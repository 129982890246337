// Toggle global options
$enable-shadows: true;

// Customize some defaults
$body-color: #333;
$body-bg: #fff;
$border-radius: .4rem;
$success: #7952b3;
$primary: #2e4573;

html {
  font-family: 'Roboto, Sans-Serif', sans-serif;
}

body {
  font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
  font-size: 16px;
  color: rgb(44, 62, 80);
  padding-top: 60px; 
}

.navbar {
  padding: .2rem 0;
}

h1 {
  font-size: 3rem;
  font-weight: 600 !important;
}

h3 {
  font-size: 1.6rem;
}

h2 {
  font-size: 2.2rem;
  font-weight: 600;

  ~ p {
    text-align: justify;
  }
}

p {
  line-height: 1.7rem;
}

//
// Custom styles
//
.menu {
  font-size: .9rem;
  font-family: inherit;
  line-height: 1.4rem;
  font-weight: 500;
  color: #fff;
  background-color: rgba(255, 255, 255, 0);
  border: none;
  display: inline-block;
  padding: .8rem 1.4rem;
}

.dropdown-item {
  display: block;
  line-height: 1.7rem;
  font-weight: 400;
  margin-bottom: 0;
  padding: 0 1.5rem 0 1.25rem;
  color: $body-color
}

.dropdown-item:hover {
  text-decoration: none;
  background-color: rgba(255, 255, 255, 0);
  color: #6cb738;
}


.jumbotron {
  padding-top: 2rem;
  padding-bottom: 2rem;
  margin-bottom: 0;
  background-color: #fff;
}

.jumbotron-img {
  width: 600px;
  max-width: 95%; 
}

.jumbotron p:last-child {
  margin-bottom: 0;
}

.jumbotron .container {
  max-width: 40rem;
}

footer {
  padding-top: 3rem;
  padding-bottom: 3rem;
  padding: 2.5rem;
  border-top: 1px solid #eaecef;
  text-align: center;
  color: #4e6e8e;
}

.bg-primary {
  background-color: $primary !important;
}

.vue-button {
  display: inline-block;
  font-size: 1.2rem;
  padding: .8rem 1.4rem;
  border-radius: 4px;
  transition: background-color .1s ease;
  box-sizing: border-box;
  background-color: $primary;
  text-align: left;
  font-weight: 500;
  color: white !important;
  text-decoration: none !important;
  width: 100%;

  &:hover {
    background-color: lighten($color: $primary, $amount: 5%);
  }

  img {
    width: 24px;
    height: 24px;
    padding-bottom: 4px;
    margin-right: 4px;
  }

  &.btn-secondary.dropdown-toggle,
  &.btn-secondary.dropdown-toggle:focus,
  &.btn-secondary.dropdown-toggle:active {
    border: none !important;
    box-shadow: none !important;
    background-color: $primary !important;
  }

  @media (max-width: 575.98px) {
    margin-bottom: .5rem;
  }
}


.back-to-top {
  position: absolute;
  right: 2.5rem;
  bottom: 2rem;
  &:hover {
      opacity: .75;
  }
}

a {
  font-weight: 500;
  text-decoration: none;
  text-decoration: none;
  color: #6cb738;
  &:hover {
    text-decoration: underline;
    color: #6cb738;
  }
}
